/*eslint-disable no-unused-vars, no-undef, no-console */

import '../styles/main.scss';
import * as $ from 'jquery';
// import Swiper from 'swiper/dist/js/swiper';
// import 'waypoints/lib/jquery.waypoints.js';


// CONSOLE

let c = console.log.bind(console);

// VIEWPORT

let wh = window.innerHeight,
		ww = window.innerWidth,
		ch = document.innerHeight;

// ELEMENTS

const html = document.querySelector('html');
const body = document.querySelector('body');

/// header

let header = document.querySelector('#header');
let headerHeight = header.offsetHeight;

/// main

let main = document.querySelector('#main');
let mainHeight = main.offsetHeight;

// SCROLL

let y = 0;
let lastScrollTop = 0;

// SLIDER VARs

var swipers = [],
		args = '';

// BROWSERS

if(!!window.MSInputMethodContext && !!document.documentMode) {
	body.classList.add('browser-oldie');
}

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// ENGINE

const Engine = {
	ui: {
		init: function() {

			// FUNCTIONS

			// Engine.ui.swipers();
			Engine.ui.events();
			Engine.ui.anchors();
			//Engine.ui.apis();

		},
		apis: function() {

			const endPoint = 'https://marketingapi.patria-finance.cz/api/campaigns/markets/FBF86DB4-EF17-46AA-958D-56DBDDFC8C73/history';

			fetch(endPoint, {
				method: 'GET'
			})
		  .then(response => response.json())
		  .then(data => console.log(data));

		},
		anchors: function() {

			// ANCHORS

			$('.anchor').on('click', function(e) {

				var $target = $($(this).attr('href'));
				$('html, body').stop().animate({scrollTop: $target.offset().top}, 500);

				e.preventDefault();
			});

		},
		events: function() {

			const form = document.querySelector('#form');
			const formOpeners = document.querySelectorAll('.js-open-form');
			console.log(formOpeners);
			formOpeners.forEach(opener => opener.addEventListener('click', openForm));

			const formCloser = document.querySelector('.js-close-form');
			formCloser.addEventListener('click',closeForm);

			function openForm(e) {
				form.classList.add('js-opened');
				e.preventDefault();
			}

			function closeForm(e) {
				form.classList.remove('js-opened');
				e.preventDefault();
			}

		},
		// swipers: function() {
		//
		// 	// SLIDER - FULLSCREEN
		//
		// 	var elements = document.querySelectorAll('.swiper-container');
		// 	Array.prototype.forEach.call(elements, function(el, i) {
		//
		// 		if (el.classList.contains('js-slider-normal')) {
		//
		// 			// SLIDER - NORMAL
		//
		// 			args = {
		// 				speed: 700,
		// 				effect: 'slide',
		// 				loop: true,
		// 			}
		//
		// 		} else if (el.classList.contains('js-slider-inline')) {
		//
		// 			// SLIDER - INLINE
		//
		// 			args = {
		// 				speed: 700,
		// 				effect: 'slide',
		// 				loop: false,
		// 				slidesPerView: 'auto',
		// 				spaceBetween: 30,
		// 				pagination: {
		// 					el: el.nextElementSibling,
		// 					clickable: true,
		// 				},
		// 				on: {
		// 					init: function () {
		//
		// 						const totalElement = el.parentNode.querySelector('.slide-inline__length');
		// 						if(totalElement !== null) totalElement.innerHTML = this.slides.length;
		//
		// 					},
		// 					paginationUpdate: function () {
		//
		// 						const currentElement = el.parentNode.querySelector('.slide-inline__current');
		//
		// 						if(currentElement !== null) {
		//
		// 							const child = el.parentNode.querySelector('.swiper-pagination-bullet-active');
		// 							const parent = child.parentNode;
		// 							const index = Array.prototype.indexOf.call(parent.children, child);
		//
		// 							currentElement.innerHTML = index + 1;
		//
		// 						}
		// 					},
		// 				},
		// 			};
		//
		// 		}
		//
		// 		swipers[i] = new Swiper(el, args);
		//
		//
		// 	});
		//
		// },
	},
};

window.onload = function() {

	Engine.ui.init();
	//Engine.ui.ajax();
	//Engine.ui.scroll();

};
